/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { loadTranslations } from '@angular/localize';
import { registerLocaleData } from '@angular/common';

boot();

async function boot() {

  // Read locale from local storage before app initialization
  const appLang = getLanguage('preferred-language') ?? 'en';

  await initLanguage(appLang);
  const comp = await import("./app/app.module");

  try {
    await platformBrowserDynamic().bootstrapModule(comp.AppModule);
  } catch (err) {
    console.error(err);
  }
}

function getLanguage(name: string) {
  const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const [key, value] = cookie.trim().split('=');
        if (key?.trim() === name) return value?.trim();
    }
    return null;
}

async function initLanguage(locale: string): Promise<void> {
  if (locale === "en") {
    // Default behavior, no changes required
    return;
  }

  try {
    // Fetch XLIFF translation file and transform to JSON format (JSON translations can be used directly)
    const response = await fetch("/i18n/messages." + locale + ".json");
    if (response.status !== 200) {
      console.warn('Translation not found for locale: ' + locale);
      return;
    }

    const json = await response.json();

    // Initialize translation
    loadTranslations(json);
    $localize.locale = locale;

    // Load required locale module (needs to be adjusted for different locales)
    let localeModule;
    switch (locale) {
      case 'da':
        localeModule = await import(`../node_modules/@angular/common/locales/da`);
        break;
      case 'de':
        localeModule = await import(`../node_modules/@angular/common/locales/de`);
        break;
      case 'nb':
        localeModule = await import(`../node_modules/@angular/common/locales/nb`);
        break;
      case 'fr':
        localeModule = await import(`../node_modules/@angular/common/locales/fr`);
        break;
      case 'sv':
        localeModule = await import(`../node_modules/@angular/common/locales/sv`);
        break;
      default:
        console.warn('Translation not support for locale: ' + locale);
        return;
    }

    // Ensure document lang is set accordingly.
    document.documentElement.setAttribute('lang', locale);

    registerLocaleData(localeModule.default);
  } catch (err) {
    console.error(err);
  }
}